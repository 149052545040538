@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.root {
  display:flex;
  flex-wrap: wrap;  
  background: linear-gradient(to right,
  rgba(50, 68, 112,1) 2%,     
  rgba(43, 56, 89, 1) 15%,
  rgba(57, 62, 73, 1) 75%
 );
  height: 100vh;
  align-items: center;
  -webkit-animation: imagenShow ease-in-out;
          animation: imagenShow ease-in-out;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.FirstTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Montserrat Alternates', sans-serif;
  width: 100vw;  
  height: 100px;
  font-size: 32px;
  color: white;
  
}
.SecondTitle {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
  font-family: 'Montserrat Alternates', sans-serif;
  width: 100vw;
  font-size: 16px;
  color:#C3CADB;
}

.Imagen {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100px;
  justify-content: center;
  margin-bottom: 10px;
}

@-webkit-keyframes imagenShow {
  0% { opacity: 0}
  100% { opacity: 1}
}

@keyframes imagenShow {
  0% { opacity: 0}
  100% { opacity: 1}
}

.Last {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  padding: 10px;
  width:80vw;
  margin-top: 10px;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 16px;
  color: #C4EEE4;
}



.line-1{
 /* position: relative;*/
  top: 50%;  
  width: 41em;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  /*font-size: 180%;*/
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
-webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) infinite normal;
        animation: typewriter 4s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) infinite normal;
}
@-webkit-keyframes typewriter{
from{width: 0px;}
to{width: 650px;}
}
@keyframes typewriter{
from{width: 0px;}
to{width: 650px;}
}
@-webkit-keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}
@keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}



